<template>
  <div>
    <!-- 主体 -->
    <LiefengContent  @backClick="backClick" :isBack='true'>
      <template v-slot:title  >子系统管理</template>
      <!-- 头部搜索层 -->
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Button
              icon="ios-add"
              type="primary"
              @click="addForm"
          >新增
          </Button>
        </Form>
      </template>
      <!-- 分页层 -->
      <template v-slot:contentArea>
        <!-- 分页table -->
        <LiefengTable
            :talbeColumns="pageForm.talbeColumns"
            :tableData="pageForm.tableData"
            :loading="pageForm.loading"
            :fixTable="true"
            :curPage="pageForm.page"
            :total="pageForm.total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageForm.pageSize"
            @hadlePageSize="changeTableSize"
        ></LiefengTable>
        <FormAdd ref="FormAdd" @resetBtn="resetBtn"></FormAdd>
        <FormEdit ref="FormEdit" @resetBtn="resetBtn"></FormEdit>
        <LiefengModal
          title="授权菜单"
          :value="menuStatus"
          @input="changeMenuStatus"
          width="500px"
          height="600px"
          >
          <template v-slot:contentarea>
            <Tree 
              :data="menuTree" 
              show-checkbox
            >
            </Tree>
          </template>
          <template v-slot:toolsbar>
             <Button type="primary" style="margin-right:10px" @click="saveMenu">保存</Button>
             <Button type="info" @click="changeMenuStatus(false)">取消</Button>
          </template>
        </LiefengModal>
      </template>
      
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormAdd from './childrens/add'
import FormEdit from './childrens/edit'

export default {
  components: {LiefengContent, LiefengTable, LiefengModal, FormAdd, FormEdit},
  data() {
    return {
      //租户id，有可能会传
      tenantId: '',
      //分页参数
      pageForm: {
        talbeColumns: [
          {
            title: "系统名称",
            minWidth: 200,
            align: "center",
            render:(h,params) => {
                return h(
                    'div',{},params.row.extra.IndexParam.systemName?params.row.extra.IndexParam.systemName:''
                )
            }
          },
          {
            title: "系统编码",
            minWidth: 200,
            align: "center",
            render:(h,params) => {
                return h(
                    'div',{},params.row.extra.IndexParam.systemCode?params.row.extra.IndexParam.systemCode:''
                )
            }
          },
          {
            title: "租户名称",
            key: "oemName",
            minWidth: 200,
            align: "center"
          },
          {
            title: "系统类型",
            key: "appName",
            minWidth: 200,
            align: "center"
          },
          {
            title: "系统logo",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              if (!params.row.extra.IndexParam.systemLogo) {
                return h("div", {}, "无")
              }
              return h("div",[h("Avatar",
                  {
                    props:{
                      src: params.row.extra.IndexParam.systemLogo
                    }
                  })])
            }
          },
          {
            title: "形象图片",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              if (!params.row.extra.IndexParam.systemImage) {
                return h("div", {}, "无")
              }
              return h("div",[h("Avatar",
                {
                  props:{
                    src: params.row.extra.IndexParam.systemImage
                  }
                })])
            }
          },
          {
            title: "备注",
            key: "remark",
            minWidth: 200,
            align: "center",
            render:(h,params) => {
                return h(
                    'div',{},params.row.extra.IndexParam.remark?params.row.extra.IndexParam.remark:''
                )
            }
          },
          {
            title: "创建时间",
            key: "gmtCreate",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              return h(
                'div',{},params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
              )
            }
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.getDetail(params.row.tenantSystemId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "修改"
                                ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.openMenuTree(params.row)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "授权菜单"
                                ),

                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                              this.deleteRow(params.row.tenantSystemId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0
      },
      //查询参数
      searchForm: {
        keyword: ''
      },

      menuStatus:false,
      menuTree:[],
      orgSystemId:"",
      orgSystemModuleList:[],

      tenantSystemId:'',
    }
  },
  //create函数,刚加载页面时调用
  async created() {
    this.tenantId = this.$route.query.tenantId;
    //不是从子平台点进来的就要获取全部租户
    if (!this.tenantId) {
      this.getAllTenant();
    }
    this.getFatherSystemList();
    await this.selectPage()
  },
  methods: {
    /*----- 分页事件部分 -------*/
    // 菜单返回
    backClick(){
      this.$router.push(`/warrantsystem?tenantId=${this.$route.query.tenantId}`)
    },

    //分页事件改变
    changeTableSize(val) {
      this.pageForm.page = val.page
      this.pageForm.pageSize = val.pageSize
      this.selectPage()
    },
    //查询按钮
    searchBtn() {
      this.pageForm.page = 1
      this.selectPage()
    },
    //重置按钮
    resetBtn() {
      this.searchForm = {
        keyword: ''
      }
      this.pageForm.page = 1
      this.selectPage()
    },


    /*----- modal部分 -------*/
    //新增按钮
    addForm() {
      this.$refs.FormAdd.controlForm.formFlag = true;
    },
    // 方法部分

    /*----- 接口部分 -------*/
    async getAllTenant() {
      await this.$get('/auth/api/auth/pc/TenantApi/selectByNoPage', {
      }).then(res => {
        if (res.code == 200) {
          this.$refs.FormAdd.controlForm.tenantList = res.dataList
          this.$refs.FormEdit.controlForm.tenantList = res.dataList
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    async getFatherSystemList() {
      await this.$get('/auth/api/auth/pc/system/selectAllFatherSystem', {
      }).then(res => {
        if (res.code == 200) {
          //parentid为0时，为无
          // res.dataList.unshift({systemId : '0', appName : '无'});
          this.$refs.FormAdd.controlForm.fatherSystemList = res.dataList
          this.$refs.FormEdit.controlForm.fatherSystemList = res.dataList
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },

    async selectPage() {
      this.pageForm.loading = true
      if (!this.tenantId) {
        this.tenantId = ''
      }
      await this.$get('/auth/api/auth/pc/tenantSystem/selectByPage', {
        page: this.pageForm.page,
        pageSize: this.pageForm.pageSize,
        ...this.searchForm,
        parentId:this.$route.query.tenantSystemId
        // tenantId: this.tenantId,
        // systemId:this.$route.query.systemId
      }).then(res => {
        this.pageForm.loading = false
        if (res.code == 200) {
          this.pageForm.tableData = res.dataList.map( e=> {
            if(e.extra){
              let obj = JSON.parse(e.extra.IndexParam) 
              e.extra.IndexParam = obj
              return{
                ...e,
              }
            }else{
              let obj = {IndexParam:{systemIndex:''}}
              e.extra = obj
              return {
                ...e
              }
            }
          })
          console.log(this.pageForm.tableData)
          this.pageForm.total = res.maxCount
          this.pageForm.currentPage = res.currentPage
          this.$refs.FormAdd.tenantId = this.tenantId
          this.$refs.FormEdit.tenantId = this.tenantId
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 获取详情接口
    getDetail(tenantSystemId){
      this.$refs.FormEdit.getSonSystemDetail(tenantSystemId)
      this.$refs.FormEdit.controlForm.formFlag = true
    },
    async deleteRow(tenantSystemId) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对系统进行删除操作、请确保系统下无数据、并且当前操作不可逆转，是否继续？",
        onOk: () => {
          this.$post('/auth/api/auth/pc/tenantSystem/delete',{
            tenantSystemId
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.resetBtn();
                }else{
                    this.$Message.error({
                        content:'删除失败',
                        background:true
                    })
                    return
                }
            })
        },
      });
    },

    // 授权菜单框
    changeMenuStatus(val){
      this.menuStatus = val
    },
    // 获取数勾选值
    getsavelist(arr,list){
      list.map( e => {
        if(e.checked){
          arr.push(e.value)
        }
        if(e.children){
          this.getsavelist(arr,e.children)
        }
      })
    },
    // 保存授权菜单
    saveMenu(){
      let savelist = []
      this.getsavelist(savelist,this.menuTree)
      this.$post('/auth/api/auth/pc/tenantSystem/authorizationMenu',{
        menuIds:savelist.join(','),
        // orgCode:parent.vue.loginInfo.userinfo.orgCode,
        tenantSystemId:this.tenantSystemId
      }).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content: '授权成功',
            background: true
          })
          this.menuStatus = false
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    }, 

    openMenuTree(data){
      this.menuStatus = true
      this.getModuleByOrgSystemId(data)
      this.tenantSystemId = data.tenantSystemId
    },
    // 获取已经授权的菜单
    getModuleByOrgSystemId(data){
      this.$get('/auth/api/auth/pc/tenantSystem/getTenantSystemModuleList',{
        tenantSystemId:data.tenantSystemId
      }).then( res => {
        if(res.code == 200){
          this.orgSystemModuleList = res.dataList.map( e => e.sysModuleId)
          this.getMenuTree(this.$route.query.tenantSystemId)
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },
    // 获取菜单
    getMenuTree(tenantSystemId){
      this.$get('/auth/api/auth/pc/systemModule/selectAllMenuTree',{
        tenantSystemId,
      }).then(res => {
        if(res.code == 200){
          this.menuTree = res.dataList
          this.handleMenuTree(this.menuTree)
        }else {
          this.$Message.error({
            content: res.desc,
            background: true
          })
        }
      })
    },
    handleMenuTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.name,
          expand : true,
          children: e.child,
          value:e.sysModuleId,
        }
        if(this.orgSystemModuleList.includes(e.sysModuleId)){
          list[i].checked = true
        }else {
          list[i].checked = false
        }
        if (list[i].children) {
          this.handleMenuTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },



  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
</style>